.btn-action {
    width: 9em;
    height: 2.2em;
    border-radius: 20px;
    border: 0;
    cursor: pointer;
    background: transparent;
}

.btn-action-link {
    width: 9em;
    padding: 5px 2.2em;
    border-radius: 20px;
    border: 0;
    background: transparent;
    text-decoration: none;
}

.btn-white {
    border: 2px solid #fff;
    color: #fff;
    transition: background-color .3s ease-in-out;
}

.btn-white:hover  {
    background: #fff;
    color: var(--primary-color);
}

.btn-red {
    border: 2px solid var(--secondary-color);
    color: #fff;
    background: var(--secondary-color);
    transition: background-color .3s ease-in-out;
}

.btn-red:hover  {
    color: var(--secondary-color);
    background: transparent;
}

