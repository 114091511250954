.hero-section {
    width: 100%;
    height: 45em;
    position: relative;
}

.bg-hero-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.container-text-hero-section {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
}
.img-bg-text {
    width: 50%;
    height: 95%;
    object-fit: cover;
}

.wrapper_text_hero_section {
    position: absolute;
    width: 45%;
    height: 95%;
    bottom: 0;
    left: 3%;
    border-top-left-radius: 45%;
    border-top-right-radius: 45%;
    background-color: rgba(82,119,247,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.content-img {
    padding: 0 13%;
    padding-top: 17%;
}

.subtitle-hero-section {
    font-size: 1.8em;
    font-weight: bold;
    color: #fff;
    width: 100%;
    line-height: 1.1em;
    text-align: justify;
    margin-bottom: 1em;
}

.title-hero-section {
    font-size: 3.5em;
    color: #fff;
    padding-bottom: .4em;
    word-wrap: break-word;
}


@media only screen and (max-width: 992px) {
    .hero-section {
        width: 100%;
        height: 32em;
    }
    .img-bg-text {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .wrapper_text_hero_section {
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .content-img {
        padding: 0 20px;
        padding-top: 0;
    }
    .subtitle-hero-section {
        font-size: 1.5em;
        font-weight: bold;
        color: #fff;
        width: 100%;
        line-height: 1.1em;
        text-align: justify;
        margin-bottom: 1em;
    }
    .title-hero-section {
        font-size: 2.5em;
        color: #fff;
        padding-bottom: .4em;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .wrapper_text_hero_section { 
        width: 55%;
    }
}