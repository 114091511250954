.floating-buttons {
    background-color: var(--primary-color);
    height: 7.9em;
    width: 5em;
    padding: 10px;
    position: absolute;
    top: 20%;
    right: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.floating-buttons__button {
    width: 2.6em;
    height: 2.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    transition: opacity .3s ease-in-out;
}

.floating-buttons__button:hover {
    opacity: .7;
}

.img-floating {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media only screen and  (max-width: 768px) {
    .floating-buttons {
        display: none;
    }    
}
