.wrapper_text_slide_content {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .wrapper_text_slide_content h5 {
    font-size: 1em;
  }
  .wrapper_text_slide_content span {
    font-size: 0.8em;
  }
}
