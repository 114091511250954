.spinner-view {
    position: fixed;
    background-attachment: fixed;
    background-color: rgba(0,0,0,.8);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
}
.container-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.text-spinner {
    color: #fff;
    font-size: 2em;
}