:root {
    /* Principal colors */
    --primary-color: #5277f7;
    --secondary-color: #ab2732;
    --tertiary-color: #e6e6e6;
    --blue-dark-color: #1b1464;
    --blue-dark-color-opacity: rgb(27, 20, 100, 0.7);
    --light-color: #e6e6e6;
    --light-color-opacity: rgb(230, 230, 230,0.7);


    /* Secondary colors */
    --ligth-color: #ffffff;
    --dark-color: #000000;
    --blue-light-color: #acbcfc;
    --gray-light-color: #666666;
}