.container-about {
  width: 100%;
  height: 45em;
}

.swiper-about {
  width: 100%;
  height: 100%;
}

.swiper-slide-about {
  width: 100%;
  height: 100%;
}

.swiper-about .swiper-pagination-bullet {
  width: 27px;
  height: 27px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-about .swiper-pagination-bullet-active {
  color: #fff;
  background: #fff;
  border: 7px solid var(--secondary-color);
}



@media only screen and (max-width: 992px) {
  .container-about {
    height: 100%;
  }
  .swiper-about {
    padding-bottom: 2em;
  }
}