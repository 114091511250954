.solution-section {
  margin-top: 3em;
  width: 100%;
  height: 100%;
}

.title-solutions {
  font-size: 3em;
  color: var(--blue-dark-color);
  text-align: center;
}

.wrapper-swiper-solution {
  margin-top: 1em;
  background-color: var(--gray-light-color);
  width: 100%;
  height: 100%;
}

.swiper-solution {
  width: 70%;
  height: 100%;
  padding: 1em 0;
}

.swiper-slide-solution {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .solution-section {
    margin-top: 3em;
    width: 100%;
    height: 100%;
  }
  .swiper-solution {
    width: 90%;
    height: 100%;
  }

  .wrapper-swiper-solution {
    margin-top: 2em;
    height: 100%;
  }

  .swiper-slide-solution {
    padding: 1em 0;
  
  }
}
