.slide-client__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f9ff;
    border-radius: 20px;
    box-shadow: 5px 5px 0px 0px #e4ecfe;
    flex-direction: column;
}

.slide-client__img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
}

.slide-client__description {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    display: none;
    transition: padding-top 0.3s ease-in-out;
}

.label-project {
    color: var(--blue-light-color);
}

.slide-client__text {
    font-weight: 400;
    display: block;
}

.slide-client__wrapper:hover {
    padding-top: 1.5em;
}

.slide-client__wrapper:hover .slide-client__img{
    height: 60%;
    width: 60%;
}

.slide-client__wrapper:hover .slide-client__description{
    padding-top: 0em;
    display: flex;
}

/* Swiper nest styles */
.mySwiper2 {
    width: 100%;
    height: 100%;
}

.mySwiper2 .swiper-slide {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 0 5%;
}