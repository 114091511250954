.nav-item{
  margin: 0 1rem;
  cursor: pointer;
  position: relative;
  user-select: none;
}


.nav-link{
  font-weight: bold;
}

.nav-link:before, .nav-link:after{
	content: '';
	position: absolute;
	bottom: 5px;
	width: 0%;
	height: 2px;
	background-color: var(--primary-color);
	transition: .4s;
}

.nav-link:before{
	right: 50%;
}

.nav-link:after{
	left: 50%;
}

.nav-link:hover:before, .nav-link:hover:after{
	width: 50%;
}
.nav-link:hover {
	  color: var(--primary-color)!important;
}


@media only screen and (max-width: 992px) {
	.nav-link:hover:before, .nav-link:hover:after{
		width: 27%;
	}

	.nav-link:before{
		right: 73%;
	}

	.nav-link:after{
		left: 0%;
	}
}