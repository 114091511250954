.contact-us {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper_form {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 1em 2em;
}

.title-contact {
    font-size: 3em;
    color: var(--secondary-color);
    padding: 1em 0;
    font-weight: bold;
}

.form-contact {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn-wrapper-contact {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.text-area-control {
    width: 100%;
    height: 2.8em;
    width: 100%;
    min-height: 7em;
    max-height: 12em;
    margin-bottom: .5em;
}

.input-group-contact {
    width: 100%;
    height: 2.8em;
    margin-bottom: 1em;
}

.input-group-text-area {
    width: 100%;
    margin-bottom: 1em;
}
.input-control-contact {
    width: 100%;
    height: 100%;
    background-color: #e8e4e4;
    border: none;
    padding-left: 1em;
}

@media only screen and (max-width: 768px) {
    .wrapper_form {
        width: 90%;
    }
}