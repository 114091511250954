.clients {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 45em;
  background-color: var(--light-color);
  padding-bottom: 4em;
}

.title-section-clients {
  text-align: center;
  font-size: 3em;
  color: #555;
  padding: 1em 0;
}

.wrapper-slider-client {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4em;
  background-color: #fff;
}

.swiper-client {
  width: 100%;
  height: 26.2em;
  padding: 1em 3em;
}

@media only screen and (max-width: 768px) {
  .wrapper-slider-client {
    padding: 0 1em;
  }

  .swiper-client {
    width: 100%;
    height: 25em;
    padding: 1em 3px;
  }
}
  