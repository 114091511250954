.App {
  max-width: 1500px;
  margin: 0 auto;
  width: 100vw;
}


@media only screen and (max-width: 992px) {
  .App {
    max-width: initial;
  }
}