.line-section {
  margin: 1.2em 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .line {
  background-color: var(--light-color);
  width: 95%;
  height: 3px;
} */