footer {
    margin: 2em 0;
}
.footer__wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1em;
    align-items: center;
    justify-items: center;
    padding: 2em 2em;
    border-top: 3px solid var(--light-color);
    border-bottom: 3px solid var(--light-color);
}

.wrapper-img-footer {
    width: 10em;
    height: 5em;
}

.wrapper-social {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-footer {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.wrapper-location {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.link-social {
    width: 3.2em;
    height: 3.2em;
    margin: 0 1em;
    transition: opacity 0.3s ease-in-out;
}

.link-social:hover {
    opacity: 0.7;
}
.social-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 768px) {
    .footer__wrapper {
        grid-template-columns: 1fr;
    }
    .link-social {
        width: 2.5em;
        height: 2.5em;
        margin: 0 .5em;
    }
}
    