.my-navbar {
    padding: 0 8em;
}
.container-logo {
    width: 9em;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.logo-navbar {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 992px) {
    .my-navbar {
        padding: 0;
    }
}