.service-section {
    width: 100%;
    background-color: #fff;
    margin-top: 1em;
}
.grid-services {
    /* Grid two columns and two rows*/
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(320px, auto);
    grid-gap: 1em;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.service-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image-service-wrapper {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-service {
    object-fit: cover;
    width: 45%;
}


.content-item {
    width: 60%;
    height: 100%;
}

.text-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding: 0 1em;
    transition: left .3s;
}

.light-color {
    background-color: var(--light-color);
}

.light-color:hover {
    background-color: var(--light-color-opacity);
}
.dark-color {
    background-color: var(--blue-dark-color);
}
.dark-color:hover {
    background-color: var(--blue-dark-color-opacity);
}


.text-item {
    position: absolute;
    right: -500px;
    transition: right .3s;
    width: 90%;
}

.text-wrapper:hover {
    left: 0px;
}

.text-wrapper:hover .text-item{
    position: relative;
    right: 0;
}

.animation_text {
    text-align: justify;
}

.title-service {
    font-size: 3em;
    color: var(--primary-color);
    text-align: center;
    padding: 1em 0;
}

.title-item {
    word-wrap: break-word;
}

.list-texts {
    margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
    .grid-services {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(250px, auto);
    }

    .text-item {
        font-size: .9em;
    }

    .title-service {
        font-size: 2.6em;
    }

    .text-wrapper {
        padding: 1em 1em;
    }
    .title-item {
        font-size: 1.36em;
    }
    
}