.card-section-about {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.container-text-about {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
}

.container-img-about {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.text-about {
    font-size: 1em;
    font-weight: 500;
}

.title-about {
    font-size: 2.5em;
    color: var(--secondary-color);
    padding-bottom: .3em;
}
.item-text {
    margin-bottom: 1em;
}

.img-card-about {
    width: 80%;
    height: 85%;
    object-fit: cover;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    box-shadow: 0px -30px 0px 10px var(--primary-color);
}

.list-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media only screen and (max-width: 992px) {
    .card-section-about {
        flex-direction: column;
    }
    .container-img-about {
        display: none;
    }
    .container-text-about {
        width: 100%;
    }
}